















































































































import { CheckChannelsResponse, FlowsService } from '@/includes/services/FlowService'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification } from '@/includes/services/NotificationService'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    HelpMessage
  }
})
export default class ChannelsInfoTable extends Mixins(TestStateMixin) {

  @Prop() targets!:Array<number>

  @Emit()
  updateTargets():any[] {
    return this.fullTargets
      .slice()
      .filter(t => t.status)
      .map(t => t.status && t.info.id)
  }

  fullTargets: CheckChannelsResponse['data'] = []

  channelId = ''

  searchQuery = ''

  get fullTableTargets():any[] {
    return this.fullTargets.map(((value, index) => ({
      ...value,
      index
    })))
  }

  get queriedItems(): CheckChannelsResponse['data'] {
    if (this.searchQuery) {
      return this.transformedTargetsResponseLike.filter(t => t.value.toLowerCase().includes(this.searchQuery.toLowerCase()))
    }

    return this.transformedTargetsResponseLike
  }

  get transformedTargetsResponseLike(): CheckChannelsResponse['data'] {
    return this.$store.getters.activeBoard.channels.map((target) => {
      if (target.test_state === 'Success') {
        return {
          value: target.title,
          status: true,
          info: {
            id: target.id,
            login: target.username,
            title: target.title,
            type: 'channel'
          }
        }
      } else {
        return {
          value: target.title,
          status: false,
          error: this.stateTitle(target.test_state)
        }
      }
    })
      .sort((a:any) => {
        if (!a.status) return 1
        return 0
      })
  }

  removeTarget(index:number):void {
    this.fullTargets.splice(index, 1)
    this.updateTargets()
  }

  addToFull(target:string):void {
    const existedTarget = this.transformedTargetsResponseLike.find(t => t.status && t.info.title === target)
    const alreadyExists = this.fullTargets.find(t => (t.status && (t.info.id === Number.parseInt(target) || `@${ t.info.login }` === target || t.info.title === target)) || t.value === target)

    if (alreadyExists) {
      return
    }

    if (existedTarget) {
      this.fullTargets.push(existedTarget)
      this.updateTargets()
    } else {
      this.checkChannels([ target.trim() ])
        .then(() => {
          this.updateTargets()
        })
    }
  }

  selectOption(value:string):void {
    this.addToFull(value)

    this.$nextTick(() => {
      this.channelId = ''
    })
  }

  setTarget():void {
    if (this.channelId.trim().length) {
      this.addToFull(this.channelId)
    }
  }

  checkChannels(targets:Array<string> | Array<number>):Promise<void> {
    return FlowsService.checkChannels('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      values: targets.map(t => t.toString())
    })
      .then(({ data }) => {
        this.fullTargets.push(...data)
      })
      .catch(errorNotification)
  }

  created():void {
    if (this.targets.length) {
      this.checkChannels(this.targets)
    }
  }
}
