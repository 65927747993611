var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channels-info-table"},[_c('a-table',{staticClass:"mb-2",attrs:{"data-source":_vm.fullTableTargets,"row-key":function (record) { return record.value; },"row-class-name":function (record) { return record.status ? 'success-check' : 'false-check'; },"pagination":{ pageSize:5 },"locale":{
      emptyText: _vm.$t('list_empty').toString()
    }}},[_c('a-table-column',{key:"index",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}}])}),_c('a-table-column',{key:"text",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"flex"},[_vm._v(" "+_vm._s(_vm.$t('flow_subscription_check_channel_info_title'))+" "),_c('sup',[_c('help-message',{attrs:{"help-message":_vm.$t('flow_subscription_check_channel_info_help_message', [_vm.$store.state.boardsState.activeBoard.title])}})],1)])]},proxy:true},{key:"default",fn:function(channelInfo){return _c('div',{staticClass:"flex flex-col"},[(!channelInfo.status)?[_c('span',[_vm._v(_vm._s(channelInfo.value))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(channelInfo.error))])]:[_c('span',[_vm._v(_vm._s(channelInfo.info.title))]),(channelInfo.info.login && channelInfo.info.login.length > 0)?_c('small',[_vm._v("@"+_vm._s(channelInfo.info.login))]):_c('small',[_vm._v(_vm._s(channelInfo.info.id))])]],2)}}])}),_c('a-table-column',{key:"action",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var value = ref.value;
    var status = ref.status;
    var index = ref.index;
return _c('div',{staticClass:"flex justify-end"},[(!status)?_c('a-button',{staticClass:"mr-2",attrs:{"size":"small","icon":"redo","type":"danger"},on:{"click":function($event){return _vm.checkChannels([value])}}}):_vm._e(),_c('a-button',{attrs:{"size":"small","icon":"delete","type":"danger"},on:{"click":function($event){return _vm.removeTarget(index)}}})],1)}}])})],1),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"a-auto-complete__wrapper w-full mr-2",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setTarget.apply(null, arguments)}}},[_c('a-auto-complete',{staticClass:"w-full",on:{"search":function($event){_vm.searchQuery = $event},"select":_vm.selectOption},scopedSlots:_vm._u([{key:"dataSource",fn:function(){return _vm._l((_vm.queriedItems),function(channel){return _c('a-select-option',{key:channel.value,class:{'text-danger opacity-50': !channel.status},attrs:{"disabled":!channel.status}},[_vm._v(" "+_vm._s(channel.value)+" ")])})},proxy:true}]),model:{value:(_vm.channelId),callback:function ($$v) {_vm.channelId=$$v},expression:"channelId"}})],1),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.setTarget}},[_vm._v(" "+_vm._s(_vm.$t('add_button'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }