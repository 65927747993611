






















import ForwardMessageActionModel from "./ForwardMessageActionModel";
import { InputSetups } from "@/mixins/input-setups";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';
import CheckChannelsList from "@/components/Post/CheckChannelsList/CheckChannelsList.vue";
import ChannelsInfoTable from "@/components/ChannelsInfoTable.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

@Component({
  data() {
    return {
    }
  },
  components: {
    SelectInput,
    ChannelsInfoTable,
    CheckChannelsList
  }
})
export default class ForwardMessageActionView extends Mixins(InputSetups) {
  @VModel() model!: ForwardMessageActionModel

  @Prop() disabled!: boolean
}
