var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forward-message-action-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'mode',
        'prefix': 'forward_action_',
        'options': _vm.forwardMessageActionModeOptions,
        'clearable': false,
      }
    }}}),_c('channels-info-table',{attrs:{"targets":_vm.model.targets},on:{"update-targets":function($event){_vm.model.targets = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }